import { useEffect, useState } from "react";
import Slick from "react-slick";
import styled from "styled-components";
import Detail from "./Detail";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledCarousel = styled.div`
    .slick-list {
        transition: height 0.5s ease-in-out 0s;
    }
    & .slick-slide.slick-active {
        overflow: auto;
    }
`;

export default function Carousel({ images, onChange, fit, visibleHandler }) {
    const [swipeable, setSwipeable] = useState(fit ? true : false);
    const slickSetting = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: swipeable,
        arrows: false,
        adaptiveHeight: true
    };

    function handleScroll(e) {
        e.stopPropagation();
        const scrollLeft = e.target.scrollLeft;
        const scrollWidth = e.target.scrollWidth;
        const clientWidth = e.target.clientWidth;

        console.log(window.innerWidth, scrollLeft);

        if (visibleHandler) {
            visibleHandler((scrollLeft / window.innerWidth) * 100 > 40);
        }

        if (scrollLeft <= 5) {
            setSwipeable(true);
            console.log("스크롤이 가장 왼쪽에 도달했습니다.");
        } else if (scrollLeft + clientWidth === scrollWidth) {
            setSwipeable(true);
            console.log("스크롤이 가장 오른쪽에 도달했습니다.");
        } else {
            setSwipeable(false);
        }
    }

    if (!fit) {
        useEffect(() => {
            return () => {
                document
                    .querySelectorAll(".slick-slide.slick-active")
                    .forEach((elem) => {
                        elem.addEventListener("scroll", handleScroll, {
                            passive: true
                        });
                    });
            };
        }, []);
    }

    return (
        <StyledCarousel>
            <Slick {...slickSetting} onSwipe={onChange}>
                {images.map((image, i) => {
                    return (
                        <>
                            <img
                                key={`img_${i}`}
                                src={image.image}
                                alt={image.description}
                                style={{
                                    width: fit ? "100%" : null,
                                    height: fit
                                        ? null
                                        : window.innerHeight - 130
                                }}
                            />
                            {image.products && (
                                <Detail images={image.products} />
                            )}
                        </>
                    );
                })}
            </Slick>
        </StyledCarousel>
    );
}
