import styled from "styled-components";
import news_01 from "../images/news_01.png";
const Wrapper = styled.div`
    margin: 5vh 0 0;
    & > p:nth-of-type(1) {
        text-align: center;
    }
    & > img {
        width: 100%;
    }
`;
export default function NewsComponent() {
    return (
        <Wrapper>
            <p>News</p>
            <img src={news_01} alt="news" />
        </Wrapper>
    );
}
