import v_01 from "../videos/689.MP4";
import styled from "styled-components";

const Video = styled.video`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
`;
export default function TopVideo() {
    return (
        <Video
            autoPlay
            muted
            loop
            playsInline={true}
            controls={false}
            width="100%"
        >
            <source src={v_01} type="video/mp4" />
        </Video>
    );
}
