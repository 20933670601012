import styled from "styled-components";

const Wrapper = styled.div`
    width: 100%;
    margin: 5vh 0 0;
    & > p {
        margin-left: 10vw;
    }
`;

const StyledConcept = styled.li`
    padding: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > span {
        width: 100%;
    }
    & p.main-title {
        font-size: 1.5rem;
    }
    & p.sub-title {
        font-size: 1rem;
        margin: 5vw 0;
    }
    & > img {
        width: 95%;
        margin: 0 5vw 5vw;
    }
`;

const SubProduct = styled.li`
    padding: 0 2vw;
    & > img {
        width: 100%;
    }

    & > p {
        text-align: center;
        font-size: 0.8rem;
    }
    & > p:nth-of-type(3) {
        padding: 10px;
    }
`;
export default function ConceptCarousel({ concepts }) {
    console.log(concepts);
    return (
        <Wrapper>
            <ul style={{ display: "flex", overflow: "scroll" }}>
                {concepts.map((concept, idx) => {
                    return (
                        <StyledConcept key={`c_${idx}`}>
                            <span>
                                <p className="main-title">
                                    {concept.main_title}
                                </p>
                            </span>
                            <span>
                                <p className="sub-title">{concept.sub_title}</p>
                            </span>
                            <img
                                src={concept.thumbnail}
                                alt={concept.main_title}
                            />
                            <ul style={{ display: "flex" }}>
                                {concept.products.map((prd, idx) => {
                                    return (
                                        <SubProduct key={`p_${idx}`}>
                                            <img
                                                src={prd.thumbnail}
                                                alt={prd.name}
                                            />
                                            <p>{prd.name}</p>
                                            <p>{prd.description}</p>
                                            <p>{`${prd.price.toLocaleString()}원`}</p>
                                        </SubProduct>
                                    );
                                })}
                            </ul>
                        </StyledConcept>
                    );
                })}
            </ul>
        </Wrapper>
    );
}
