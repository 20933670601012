import styled from "styled-components";

const Wrapper = styled.div`
    height: 1200px;
    display: flex;
    position: relative;
`;

const WrappedImg = styled.img`
    width: 100%;
`;

export default function Detail(props) {
    return (
        <Wrapper>
            <h1>디</h1>
            <h1>테</h1>
            <h1>일</h1>
            <h1>페</h1>
            <h1>이</h1>
            <h1>ㅈㅣ</h1>
            <h1>다</h1>
            {props.images &&
                props.images.map((img, idx) => (
                    <WrappedImg key={idx} src={img.image} alt="detail" />
                ))}
        </Wrapper>
    );
}
