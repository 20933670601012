import styled from "styled-components";

const Wrapper = styled.div`
    width: 100%;
    height: 400px;
    background-color: black;
    color: white;
    text-align: center;
    font-size: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > p:nth-of-type(1) {
        font-size: 2rem;
    }
    & > p:nth-of-type(2) {
        font-size: 1rem;
    }
`;

export default function EventComponent() {
    return (
        <Wrapper>
            <p>스타핀치 이벤트</p>
            <p>함께 하세요</p>
        </Wrapper>
    );
}
