import styled from "styled-components";

const Wrapper = styled.div`
    width: 100%;
    margin: 5vh 0;
    & > p {
        margin-left: 10vw;
    }
    & > p.main-title {
        font-size: 1.5rem;
    }
    & > p.sub-title {
        font-size: 1rem;
    }
    & > ul.categories {
        display: flex;
        margin: 5vw 5vw 5vw 10vw;
    }
`;

const StyledProduct = styled.li`
    & > p {
        padding-left: 10vw;
    }

    & > p:nth-of-type(3) {
        margin: 20px 0;
    }
`;

const StyledCategory = styled.li`
    &.active {
        background-color: lightgray;
        color: gray;
        border: none;
    }
    margin: 5px;
    padding: 10px 15px;
    border: 1px solid black;
    border-radius: 20px;
    line-height: 10px;
`;

export default function MainProductCarousel(props) {
    return (
        <Wrapper>
            <p className="main-title">{props.products?.main_title}</p>
            <p className="sub-title">{props.products?.sub_title}</p>
            <ul className="categories">
                {props.products?.categories?.map((cat, idx) => {
                    return (
                        <StyledCategory
                            key={`c_${idx}`}
                            className={`${idx === 1 ? "active" : undefined}`}
                        >
                            {cat}
                        </StyledCategory>
                    );
                })}
            </ul>
            <ul style={{ display: "flex", overflow: "scroll" }}>
                {props.products?.products.map((product, idx) => (
                    <StyledProduct key={`i_${idx}`}>
                        <img
                            style={{
                                width: `${(window.innerWidth / 100) * 80}px`
                            }}
                            src={product.thumbnail}
                        />
                        <p>{product.name}</p>
                        <p>{product.description}</p>
                        <p>{`${product.price?.toLocaleString()}원`}</p>
                    </StyledProduct>
                ))}
            </ul>
        </Wrapper>
    );
}
