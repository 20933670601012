import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import headerLogo from "../images/header_logo.png";
import styled from "styled-components";
const notiBadgeAlign = {
    vertical: "bottom",
    horizontal: "right"
};

const HeaderElement = styled.li`
    font-size: 0.4rem;
    list-style: none;
`;
function StyledHeader({ children }) {
    const style = {
        // backgroundColor: "white",
        position: "fixed",
        width: "100%",
        top: 0,
        zIndex: 2
    };
    return <header style={style}>{children}</header>;
}
function StyledUl({ children }) {
    const style = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    };
    return <ul style={style}>{children}</ul>;
}

export default function Header({ notiCount = 0, cartCount = 0 }) {
    return (
        <StyledHeader>
            <StyledUl>
                <HeaderElement>
                    <IconButton>
                        <Badge
                            badgeContent={notiCount}
                            color="secondary"
                            anchorOrigin={notiBadgeAlign}
                            sx={{
                                "& .MuiBadge-badge": {
                                    bottom: "3px",
                                    right: "3px",
                                    backgroundColor: "#1976d2"
                                }
                            }}
                        >
                            <NotificationsNoneOutlinedIcon fontSize="medium" />
                        </Badge>
                    </IconButton>
                </HeaderElement>
                <HeaderElement
                    style={{
                        display: "flex",
                        alignItems: "center",
                        overflow: "hidden"
                    }}
                >
                    <img
                        style={{
                            position: "relative",
                            width: "120px"
                        }}
                        src={headerLogo}
                    />
                </HeaderElement>
                <HeaderElement>
                    <IconButton>
                        <SearchOutlinedIcon fontSize="medium" />
                    </IconButton>
                    <IconButton>
                        <Badge
                            badgeContent={cartCount}
                            color="secondary"
                            anchorOrigin={notiBadgeAlign}
                            sx={{
                                "& .MuiBadge-badge": {
                                    bottom: "3px",
                                    right: "3px",
                                    backgroundColor: "#1976d2"
                                }
                            }}
                        >
                            <ShoppingBagOutlinedIcon fontSize="medium" />
                        </Badge>
                    </IconButton>
                </HeaderElement>
            </StyledUl>
        </StyledHeader>
    );
}
