import Header from "./layout/Header";
// import Footer from "./layout/BottomNav";
import Main from "./layout/Body";
import TopVideo from "./components/TopVideo";
// import Intro from "./components/Intro";

export default function App() {
    console.log("Welcome Star-finch");
    return (
        <>
            {/*<Intro />*/}
            <TopVideo />
            <Header notiCount={2} cartCount={10} />
            <Main />
            {/*<Footer />*/}
        </>
    );
}
