import { useState } from "react";
import Carousel from "../components/Carousel";
import MainProductCarousel from "../components/MainProductCarousel";
import ConceptCarousel from "../components/ConceptCarousel";
import top_01 from "../images/top_1.png";
import top_02 from "../images/top_2.png";
import top_03 from "../images/top_3.png";
import top_04 from "../images/top_4.png";
import concept_m_01 from "../images/concept_m_01.png";
import concept_01_01 from "../images/concept_01_01.png";
import concept_01_02 from "../images/concept_01_02.png";
import concept_01_03 from "../images/concept_01_03.png";
import cat_01_01 from "../images/cat_01_01.jpg";
import cat_01_02 from "../images/cat_01_02.jpg";
import cat_01_03 from "../images/cat_01_03.jpg";
import cat_01_04 from "../images/cat_01_04.jpg";
import cat_01_05 from "../images/cat_01_05.jpg";
import main_p_01 from "../images/main_p_01.png";
import main_p_02 from "../images/main_p_02.png";
import main_p_03 from "../images/main_p_03.png";
import main_p_04 from "../images/main_p_04.png";
import logo_3_line_white from "../images/logo_3_line_white.png";

import styled from "styled-components";
import EventComponent from "../components/EventComponent";
import NewsComponent from "../components/NewsComponent";
import CompanyInfo from "../components/CompanyInfo.jsx";
const StyledWrapper = styled.div`
    position: relative;
    height: 100%;
    padding-bottom: 60px;
    top: 50px;
`;
export default function MainLookBookCarousel() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [visibleLookBook, setVisibleLookBook] = useState(false);

    const mockBooks = [
        {
            image: top_01,
            description: "description"
        },
        {
            image: top_02,
            description: "description"
        },
        {
            image: top_03,
            description: "description"
        },
        {
            image: top_04,
            description: "description"
        }
    ];

    const mockPages = [
        [
            {
                image: cat_01_01,
                products: [{ image: top_01 }]
            },
            { image: cat_01_02 },
            { image: cat_01_03 },
            { image: cat_01_04 },
            { image: cat_01_05 }
        ],
        [],
        [],
        []
    ];

    const mockMainProducts = {
        main_title: "신제품을 만나보세요",
        sub_title: "스타핀치 23S/S 신제품",
        categories: ["남성", "여성"],
        products: [
            {
                thumbnail: main_p_01,
                name: "STARFINCH X OWANGEBOY",
                description: "공용 반팔 티셔츠",
                price: 45000
            },
            {
                thumbnail: main_p_02,
                name: "STARFINCH X OWANGEBOY",
                description: "공용 반팔 티셔츠",
                price: 45000
            },
            {
                thumbnail: main_p_03,
                name: "STARFINCH X OWANGEBOY",
                description: "공용 반팔 티셔츠",
                price: 45000
            },
            {
                thumbnail: main_p_04,
                name: "STARFINCH X OWANGEBOY",
                description: "공용 반팔 티셔츠",
                price: 45000
            }
        ]
    };

    const mockConcepts = [
        {
            main_title: "일상과 아웃도어를 넘나드는 #",
            sub_title: "자연 속으로 떠나기 좋은 봄, 그 시작을 알리는 엠투엠",
            thumbnail: concept_m_01,
            products: [
                {
                    thumbnail: concept_01_01,
                    name: "23A/W STARFINCH",
                    description: "공용 맨투맨",
                    price: 85000
                },
                {
                    thumbnail: concept_01_02,
                    name: "23A/W STARFINCH",
                    description: "공용 맨투맨",
                    price: 85000
                },
                {
                    thumbnail: concept_01_03,
                    name: "23A/W STARFINCH",
                    description: "공용 맨투맨",
                    price: 85000
                }
            ]
        }
    ];

    function chageBooks(swipe) {
        if (swipe === "left") {
            next();
        } else {
            prev();
        }
    }

    function next() {
        if (activeIndex === mockBooks.length - 1) {
            setActiveIndex(0);
        } else {
            setActiveIndex(activeIndex + 1);
        }
    }

    function prev() {
        if (activeIndex === 0) {
            setActiveIndex(mockBooks.length - 1);
        } else {
            setActiveIndex(activeIndex - 1);
        }
    }

    return (
        <StyledWrapper>
            <Carousel
                images={mockBooks}
                onChange={chageBooks}
                visibleHandler={setVisibleLookBook}
            />
            {visibleLookBook && (
                <Carousel images={mockPages[activeIndex]} fit={true} />
            )}
            <MainProductCarousel products={mockMainProducts} />
            <ConceptCarousel concepts={mockConcepts} />
            <EventComponent />
            <NewsComponent />
            <div
                style={{
                    backgroundColor: "#288d48",
                    textAlign: "center",
                    padding: "10px 0"
                }}
                onClick={() => window.scrollTo(0, 0)}
            >
                <p style={{ fontSize: "2rem", color: "white" }}>⬆</p>
                <img
                    src={logo_3_line_white}
                    alt="logo"
                    style={{ width: "40%" }}
                />
            </div>
            <CompanyInfo />
        </StyledWrapper>
    );
}
